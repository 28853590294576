import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { commaSeperator } from './CommaSeprator';
import axiosCall from "../axios/axios";
import Loader from './Loader';
const Chart = (props) => {
  const [chartData, setChartData] = useState(localStorage.getItem("AvailableBalanceCustomerInfo") || 0);
  const [total, setTotal] = useState(localStorage.getItem("ActualLimit") || 0);
  const [series, setSeries] = useState([0]); // Initially set to 0 data series
  const [chartValueColor, setChartValueColor] = useState('#000'); // Orange color
  const [availableBalance, setAvailableBalance] = useState();
  const [showData, setShowData] = useState(localStorage.getItem("AvailableBalanceCustomerInfo") ?? false); // Initially set to false to hide data
  const [errormsg, setErrormsg] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myBoolean, setMyBoolean] = useState("1");
  const [buttonClicked, setButtonClicked] = useState(false);


  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setChartValueColor('#fff'); // Change color to white for dark mode
    } else {
      setChartValueColor('#000'); // Reset color to orange for light mode
    }
    setMyBoolean(localStorage.getItem('myBoolean'));
  }, []);

  // useEffect(() => {
  //   if (props !== undefined && props.data !== undefined && props.data.CREDIT_CARD_OUTSTANDINGBALANCE !== undefined && props.data.CREDIT_LIMIT !== undefined) {
  //     setChartData(props.data.CREDIT_CARD_OUTSTANDINGBALANCE);
  //     setTotal(props.data.CREDIT_LIMIT);
  //   }
  // }, [props]);
  useEffect(() => {
    const spent = ((total - Math.abs(localStorage.getItem("AvailableBalanceCustomerInfo"))))
    const availableCreditPercentage = (((total - Math.abs(spent)) / total) * 100);
    setSeries([availableCreditPercentage]);
    setAvailableBalance(localStorage.getItem("AvailableBalanceCustomerInfo"));
  }, []);


  useEffect(() => {
    const spent = ((total - Math.abs(localStorage.getItem("AvailableBalanceCustomerInfo"))))
    const availableCreditPercentage = (((total - Math.abs(spent)) / total) * 100);
    setSeries([availableCreditPercentage]);
    setAvailableBalance(localStorage.getItem("AvailableBalanceCustomerInfo"));
  }, [total, chartData]);

  const [options, setOptions] = useState({
    chart: {
      height: 300,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    colors: ["#B5D339"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          size: '70%',
          background: 'transparent',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'back',
        },
        track: {
          background: '#FB8735',
          startAngle: -135,
          endAngle: 135,
        },
        grid: {
          padding: {
            top: 0,
          },
        },
        dataLabels: {
          show: true, // Always show data labels
          name: {
            offsetY: 10,
            show: true,
            color: showData ? chartValueColor : '#000', // Set to orange if showData is false
            fontSize: '16px',
            fontFamily: 'Roboto Condensed, sans-serif',
            style: {
              fontFamily: 'Roboto Condensed, sans-serif'
            },
          },
          value: {
            max: { total },
            formatter: function (val) {

              return '₦ ' + ' ' + '✶✶✶✶✶✶'; // Display star text if showData is false
            },
            color: chartValueColor,
            fontSize: '24px',
            show: true,
            offsetY: -30,
          }
        },
      }
    },
    style: {
      fontFamily: 'Roboto Condensed'
    },
    fill: {
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'butt'
    },

    labels: ["Available Balance"],
  });

  const toggleDataVisibility = () => {
    setIsLoading(true);
    axiosCall.POST('/customer/getduocustomerinfo', { "ACCOUNTNUMBER": localStorage.getItem("AccountNo") })
      .then((response) => {
        if (response.data.status === "200" && response.data.message === "SUCCESS") {
          localStorage.setItem("AvailableBalanceCustomerInfo", response.data.data.AVAILABLE_BALANCE);
          setAvailableBalance("" + response?.data?.data?.AVAILABLE_BALANCE);
          setTotal(localStorage.getItem("ActualLimit"));
          setChartData(response.data.data.AVAILABLE_BALANCE);
          setMyBoolean("0");
          localStorage.setItem('myBoolean', 0);
          setShowData(!showData);
          setIsLoading(false);
        } else {
          setMyBoolean("1");
          localStorage.setItem('myBoolean', 1);
          setErrormsg(response.data.errordata.message);
          setModalOpen(true);
          setIsLoading(false);
        }
      }).catch(err => {
        console.log(err)
        setMyBoolean("1");
        localStorage.setItem('myBoolean', 1);
        setErrormsg("Please try After Sometime");
        setModalOpen(true);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    if (myBoolean == "0") {
      setOptions(
        {
          chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
              show: false
            }
          },
          colors: ["#B5D339"], // Use dynamic color
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              hollow: {
                margin: 0,
                size: '70%',
                background: 'transparent',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'back',
              },
              track: {
                background: '#FB8735',
                startAngle: -135,
                endAngle: 135,
              },
              grid: {
                padding: {
                  top: 0,
                },
              },
              dataLabels: {
                show: true, // Always show data labels
                name: {
                  offsetY: 10,
                  show: true,
                  color: showData ? chartValueColor : '#000',
                  fontFamily: 'Roboto Condensed, sans-serif',
                  style: {
                    fontFamily: 'Roboto Condensed, sans-serif'
                  },
                },
                value: {
                  max: { total },
                  formatter: function (val) {
                    return '₦ ' + commaSeperator(availableBalance, 2)
                  },
                  color: chartValueColor,
                  fontSize: '24px',
                  show: true,
                  offsetY: -30,
                }
              },
            }
          },
          style: {
            fontFamily: 'Roboto Condensed'
          },
          fill: {
            gradient: {
              shade: "dark",
              type: "horizontal",
              gradientToColors: ["#87D4F9"],
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'butt'
          },

          // labels: ["Available Balance"],
        }
      );
    } else {
      setOptions(
        {
          chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
              show: false
            }
          },
          colors: ["chartValueColor"], // Use dynamic color
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              hollow: {
                margin: 0,
                size: '70%',
                background: 'transparent',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'back',
              },
              track: {
                background: '#FB8735',
                startAngle: -135,
                endAngle: 135,
              },
              grid: {
                padding: {
                  top: 0,
                },
              },
              dataLabels: {
                show: true, // Always show data labels
                name: {
                  offsetY: 10,
                  show: true,
                  color: showData ? chartValueColor : '#000', // Set to orange if showData is false
                  fontSize: '16px',
                  fontFamily: 'Roboto Condensed, sans-serif',
                  style: {
                    fontFamily: 'Roboto Condensed, sans-serif'
                  },
                },
                value: {
                  max: { total },
                  formatter: function (val) {
                    return '₦ ' + ' ' + '✶✶✶✶✶'; // Display star text if showData is false
                  },
                  color: chartValueColor,
                  fontSize: '19px',
                  show: true,
                  offsetY: -30,
                }
              },
            }
          },
          style: {
            fontFamily: 'Roboto Condensed'
          },
          fill: {
            gradient: {
              shade: "dark",
              type: "horizontal",
              gradientToColors: ["#87D4F9"],
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'butt'
          },

          labels: ["Available Balance"],
        }
      );
    }
  }, [myBoolean, showData])

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleRedirect = () => {
    handleCloseModal();
  };

  return (
    <div>
      <div className='virtualSummary' >
        Virtual Credit Card Summary
      </div>
      {isLoading ? <div>
        <Loader />
        <div id="card" style={{ marginTop: '-30px', position: 'relative' }}>
          <div id="chart" style={{ position: 'relative' }}>
            <ReactApexChart className="reactcharts" options={options} series={series} type="radialBar" height={350} />
            <div> {myBoolean == "1" &&
              (<button onClick={toggleDataVisibility}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '134px',
                  left: 'calc(50% - -50px)', // Adjust the value as needed
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  outline: 'none',
                  zIndex: '999',
                }}>
                <FaEyeSlash />
              </button>)
            }
            </div>

          </div>
        </div>
      </div> :

        <div id="card" style={{ marginTop: '-30px', position: 'relative' }}>
          <div id="chart" style={{ position: 'relative' }}>
            <ReactApexChart className="reactcharts" options={options} series={series} type="radialBar" height={350} />
            <div> {myBoolean == "1" &&
              (<button onClick={toggleDataVisibility}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '134px',
                  left: 'calc(50% - -50px)', // Adjust the value as needed
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  outline: 'none',
                  zIndex: '999',
                }}>
                <FaEyeSlash />
              </button>)
            }
            </div>

          </div>
        </div>
      }
    </div>
  );
};

export default Chart;
