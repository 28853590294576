
import React, { useEffect, useState } from 'react';
import axiosBranch from '../axios/axiosBranch';
import { useHistory } from 'react-router-dom';
import Loader from './Loader';
import DUOlogo from '../assets/duologoblack.svg';

const AppHome = () => {
  const [arrayData, setArrayData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const webviewauthorization = queryParams.get("webviewauthorization");
  const webviewuserid = queryParams.get("webviewuserid");
  const customerid = queryParams.get("customerid");
  const bankcode = queryParams.get("bankcode");
  localStorage.setItem("webviewauthorization", webviewauthorization);
  localStorage.setItem("webviewuserid", webviewuserid);
  localStorage.setItem("customerid", customerid);
  localStorage.setItem("bankcode", bankcode);

  useEffect(() => {
    (async () => {
      const body = {
        "CUSTOMERID": customerid,
        "BANKCODE": "044",
      }
      setIsLoading(true);
      await axiosBranch.POST('/webview/checkcustomerid', body)
        .then((response) => {
          if (response.data.status === "200" && response.data.message === "SUCCESS") {
            const data = response?.data?.data?.daoInfo?.[0] ?? {}
            const StatusData = response?.data?.data ?? {}
            localStorage.clear()
            const dataString = JSON.stringify(data);
            const statusDataString = JSON.stringify(StatusData);  // Convert object to string
            localStorage.setItem("customeridData", dataString);
            localStorage.setItem("StatusData", statusDataString);
            localStorage.setItem('myBoolean', 1);


            const storedDataString = localStorage.getItem("customeridData");
            const storedData = JSON.parse(storedDataString); // Convert string to object

            const storedstatusDataString = localStorage.getItem("StatusData");
            const storedstatusDataData = JSON.parse(storedstatusDataString); // Convert string to object

            const respCode = data?.RESPCODE ?? '';
            const isDuoUser = data?.duoUser;

            if (respCode === "74" && isDuoUser) {
              history.push("/RegistrationPage2");
            }
            if (respCode === "74" && !isDuoUser) {
              history.push("/RegistrationPage4");
            }
            if (["70", "00", "71"].includes(respCode) &&
              !isDuoUser) {
              history.push("/RegistrationPage");
            }
            if (["70", "71"].includes(respCode) && isDuoUser) {

              history.push("/Home", { data: data, StatusData: StatusData });
            }
            if (respCode === "01") {
              history.push("/RegistrationPage1");
            }
            localStorage.setItem("clientCodeencr", response.data.clientcodeencr);
            localStorage.setItem("longtoken", response.data.data.daoInfo[0].TOKEN);
            localStorage.setItem("userId", response.data.data.daoInfo[0].USERID);
            localStorage.setItem("AccountNo", response.data.data.daoInfo[0].CARD_ACCOUNTNO);
            localStorage.setItem("CreditCardNo", response.data.data.daoInfo[0].CREDITCARD.PAN);
            localStorage.setItem("CreditLimit", response.data.data.daoInfo[0].CREDIT_LIMIT);
            localStorage.setItem("ActualLimit", response.data.data.daoInfo[0].ACTUAL_LIMIT);
            localStorage.setItem("respcode", response.data.data.daoInfo[0].RESPCODE);
            localStorage.setItem("duoUser", response.data.data.daoInfo[0].duoUser);
            localStorage.setItem("email", response.data.data.daoInfo[0].EMAIL);
            localStorage.setItem("CREDITACCOUNTNUMBER", response.data.data.daoInfo[0].CREDIT_ACCOUNTNO);
            localStorage.setItem("CardIssuer", response.data.data.daoInfo[0].CREDITCARD.CARD_ISSUER);
            localStorage.setItem("CardType", [response.data.data.daoInfo[0].CREDITCARD.CARD_TYPE]);
            localStorage.setItem("CARDSCHEME", [response.data.data.daoInfo[0].CREDITCARD.CARDSCHEME]);
            localStorage.setItem("AvailableBalance", [response.data.data.daoInfo[0].AVAILABLE_BALANCE]);
            localStorage.setItem("customerid", [response.data.data.daoInfo[0].CUSTOMERID]);
          }
          else {
            history.push("/Forbidden");
          }
          setIsLoading(false);
        }).catch(err => {
          setIsLoading(false);
          // console.error(err);
        });
    })();
  }, [])

  return (
    <div>
      {isLoading ? <div className="loaderClass">
        <div className="loaderCircle"></div>
        <img className="duologcircle" src={DUOlogo} alt="logo" />
      </div> : <></>}
    </div>
  )




}


export default AppHome;